import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import styles from "./success.module.css";
import { useParams } from "react-router-dom";
import logo from "./assets/logo_blue.svg";
import success from "./assets/success.json";

const Success = () => {
  const [senderName, setSenderName] = useState("");
  const [receiverName, setReceiverName] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [note, setNote] = useState("");
  const [receiverAccountNumber, setReceiverAccountNumber] = useState("");
  const [bankName, setBankName] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [toBank, setToBank] = useState(false);
  const [gateway,setGateway] = useState("Mpesa");
  const { ref } = useParams();
  //let gateway ="Mpesa";//(toBank ? "Bank":"Mpesa");

  useEffect(() => {
    let url = "https://super-remit.mobishare.org/sendMoney";

    if (ref.indexOf('B-') > -1){ 
      url = "https://b2b.mobishare.org/sendMoney";
      setToBank(true);
    }
    if (ref.indexOf('B-U-') > -1){
      setToBank(false); 
      setGateway("");
    }
    

    const fetchDetails = async () => {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ subject: "sendMoney", ref: ref }),
      });
      const data = await response.json();
      console.log(data);
      console.log();
      setAmount(data.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setBalance(data.balance_kes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setReceiverName(data.receiverName);
      setReceiverPhone(data.receiverPhone);
      setReceiverEmail(data.receiverEmail);
      setSenderName(data.senderName);
      setTransactionId(data.ref);
      setNote(data.note);
      if (toBank){
          setReceiverAccountNumber(data.receiverAccountNumber);
          setBankName(data.bankName);
        }
      if (ref.indexOf('B-U-') > -1){ 
        setReceiverName(data.operation);
        setReceiverEmail(data[data.operation]);
        setGateway(data.operation); 
      }
      
    };
    try {
      fetchDetails();
    } catch (error) {
      console.log(error);
    }
  }, [ref,toBank]);
  

  return (
    <div className={styles.pageContainer}>
      <div className={styles.content}>
        <div style={{ height: "350px", width: "350px" }}>
          <Lottie animationData={success} />
        </div>
        <p className={styles.pageTitle}>
          {" "}
          Thank you for your payment, {senderName}{" "}
        </p>
        <div className={styles.cardContainer}>
          {/* <div className={styles.logoContainer}> */}
          <img src={logo} alt="mobishare_logo" className={styles.blueLogo} />
          {/* </div> */}
          <p className={styles.message}>
            {" "}
            You sent a payment of ${amount} to {receiverName ==="" ? "loading...":`${receiverName} (${receiverEmail})`}.
            They will receive Ksh {balance} via {gateway !== "Mpesa" ? gateway: `${bankName ==="" ? "loading...":`${toBank ? `${bankName} (${receiverAccountNumber})`:gateway}`}`} shortly{" "}
          </p>
          <p className={styles.caveat}>
            {" "}
            It may take a few minutes for the funds to reflect in their account.{" "}
          </p>
          <div className={styles.transactionDetailsContainer}>
            <div className={styles.transactionIdContainer}>
              <p className={styles.title}> Transaction ID </p>
              <p className={styles.detail}> {transactionId} </p>
            </div>
            <div>
              <p className={styles.title}> Note to Recipient </p>
              <p className={styles.detail}> {note} </p>
            </div>
            <div className={styles.receipientContainer}>
              <p className={styles.title}> Recipient </p>
              <p className={styles.detail}> {receiverName} </p>
              <p className={styles.detail}> {receiverEmail} </p>
              <p className={styles.detail}> {receiverPhone ? receiverPhone:""} </p>
            </div>
          </div>
          <div className={styles.bottom}>
            <p className={styles.issues}> Issues with this transaction? </p>
            <p className={styles.contact}>
              {" "}
              Please email our support team at{" "}
              <span className={styles.email}>
                {" "}
                customercare@mobishare.org{" "}
              </span>{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
