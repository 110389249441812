import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";
import { useParams } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  "pk_live_51KyNfIG8aq4dLsxzvk5MnwzA7Aa9elvgNMrYaQ5ryU8z1Y2crcqIpWnK1tam6a6N0ToJz2DhiCo4Q0Q8k5rCGfPJ00YAdB5XAd"
);

export default function Initiator() {
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");
  let { ref } = useParams();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads

    let url = "https://super-remit.mobishare.org/sendMoney";

    if (ref.indexOf('B-') > -1){ 
      url = "https://b2b.mobishare.org/sendMoney";
    }
    

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ subject: "sendMoney", ref: ref }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClientSecret(data.clientSecret);
        setAmount(data.amount);
        setBalance(data.balance_kes);
      });
  }, [ref, amount]);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm amount={amount} balance={balance} />
        </Elements>
      )}
    </div>
  );
}
