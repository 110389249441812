import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Initiator from "./Initiator";
import Success from "./success";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/checkout/:ref" element={<Initiator />} />
        <Route path="/checkout/:ref/success" element={<Success />} />
      </Routes>
    </BrowserRouter>
  );
}
